import Link from 'next/link'
import { FaDiscord, FaMedium } from 'react-icons/fa'
import { FaBluesky, FaXTwitter } from 'react-icons/fa6'
import { CLIENT_BASE_URL } from 'src/data/constants'

export const socialLinks = [
  {
    name: '𝕏 / Twitter',
    href: 'https://x.com/nfdomains',
    icon: FaXTwitter
  },
  {
    name: 'Bluesky',
    href: 'https://bsky.app/profile/nfdomains.algo.xyz',
    icon: FaBluesky
  },
  {
    name: 'Discord',
    href: 'https://discord.gg/7XcuMTfeZP',
    icon: FaDiscord
  },
  {
    name: 'Medium',
    href: 'https://nfdomains.medium.com/',
    icon: FaMedium
  }
]

export default function Footer() {
  return (
    <footer
      className="xl:mt-24 bg-gray-50 dark:bg-gray-900 xl:dark:bg-gray-950"
      aria-labelledby="footer-heading"
      data-cy="home-footer"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {socialLinks.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500 dark:text-gray-600 dark:hover:text-gray-500"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <div className="text-center md:flex md:items-center md:justify-center">
            <p className="text-gray-400 dark:text-gray-600">
              &copy; {new Date().getFullYear()}{' '}
              <a
                href="https://txnlab.dev"
                className="hover:text-brand-500"
                target="_blank"
                rel="noreferrer"
              >
                TxnLab, Inc.
              </a>{' '}
              All rights reserved.
            </p>
            <p className="mt-2 md:mt-0 md:ml-3 sm:border-l md:border-gray-200 md:pl-3 text-gray-400 dark:text-gray-600 dark:md:border-gray-700">
              <Link
                href={`${CLIENT_BASE_URL}/privacy`}
                className="hover:text-brand-500"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Link>
            </p>
            <p className="mt-2 md:mt-0 md:ml-3 sm:border-l md:border-gray-200 md:pl-3 text-gray-400 dark:text-gray-600 dark:md:border-gray-700">
              <Link
                href={`${CLIENT_BASE_URL}/terms`}
                className="hover:text-brand-500"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
