import Link from 'next/link'
import { BiHomeAlt } from 'react-icons/bi'
import { HiOutlineCog } from 'react-icons/hi'
import { HiSparkles } from 'react-icons/hi2'
import { MdOutlineQueryStats, MdOutlineStorefront } from 'react-icons/md'
// import { PiChatsTeardropDuotone } from 'react-icons/pi'
// import { TbMail } from 'react-icons/tb'
import { classNames } from 'src/helpers/utilities'
import { DOCS_URL } from 'src/data/constants'
import type { IconType } from 'react-icons'
// import useGetAuctions from 'api/hooks/useGetAuctions'
// // // // import { useAuthContext } from 'context/AuthContext'

// const LiveIcon = ({ isCurrent }: { isCurrent: boolean }) => {
//   return (
//     <span
//       className={classNames(
//         isCurrent ? 'bg-brand-800' : 'bg-gray-600 group-hover:bg-gray-500',
//         'inline-flex align-middle items-center mx-0.5 px-2 py-0.5 rounded text-xs font-semibold uppercase text-white'
//       )}
//     >
//       <svg className="-ml-0.5 mr-1.5 h-2 w-2 animate-blink" fill="currentColor" viewBox="0 0 8 8">
//         <circle cx={4} cy={4} r={3} />
//       </svg>
//       Live
//     </span>
//   )
// }

type NavItem = {
  name: string
  href: string
  icon: IconType
  joyrideId?: string
}

type ResourceItem = {
  name: string
  href: string
}

const network = process.env.NEXT_PUBLIC_VERCEL_ENV

const betanetDispenser = {
  name: 'Betanet Dispenser',
  href: 'https://bank.betanet.algodev.network/'
}

const testnetDispenser = {
  name: 'Testnet Dispenser',
  href: 'https://bank.testnet.algorand.network/'
}

const resources: ResourceItem[] = [
  {
    name: 'Documentation',
    href: DOCS_URL
  },
  { name: 'Video tutorials', href: 'https://www.youtube.com/channel/UCdPzEzZhsc5vqLIRJLDClBQ' },
  {
    name: 'API Docs',
    href: 'https://api-docs.nf.domains'
  },
  ...(network === 'betanet' ? [betanetDispenser] : []),
  ...(network === 'testnet' ? [testnetDispenser] : [])
]

const community: ResourceItem[] = [
  { name: 'Discord', href: 'https://discord.gg/7XcuMTfeZP' },
  { name: '𝕏 / Twitter', href: 'https://x.com/nfdomains' },
  { name: 'Bluesky', href: 'https://bsky.app/profile/nfdomains.algo.xyz' }
]

interface NavInterface {
  pathname: string
}

type NavProps = (
  | {
      isMobile: boolean
      onClose: () => void
    }
  | {
      isMobile?: never
      onClose?: never
    }
) &
  NavInterface

export default function Nav({ pathname = '/', isMobile = false, onClose }: NavProps) {
  // const { data: auctions } = useGetAuctions({ startingSoon: false })
  // const hasAuctions = !!(auctions && auctions?.length > 0)

  // const { nfdUser } = useAuthContext()

  const navigation: NavItem[] = [
    { name: 'Home', href: '/', icon: BiHomeAlt },
    { name: 'Mint', href: '/mint', icon: HiSparkles, joyrideId: 'jr-mint' },
    // ...(!!nfdUser ? [{ name: 'Messages', href: '/messages', icon: TbMail }] : []),
    // ...(!!nfdUser ? [{ name: 'Group Chats', href: '/rooms', icon: PiChatsTeardropDuotone }] : []),
    {
      name: 'Marketplace',
      href: '/browse',
      icon: MdOutlineStorefront,
      joyrideId: 'jr-marketplace'
    },
    // {
    //   name: 'Auctions',
    //   href: '/auctions',
    //   icon: RiAuctionFill
    // },
    { name: 'Analytics', href: '/analytics', icon: MdOutlineQueryStats, joyrideId: 'jr-analytics' },
    { name: 'Manage', href: '/manage', icon: HiOutlineCog, joyrideId: 'jr-manage' }
  ]

  const getIsCurrent = (href: string) => {
    if (href === '/') return pathname === '/'

    // highlight `Browse` nav item when current page is `/name/:id`
    if (pathname.startsWith('/name')) return href.startsWith('/browse')

    // highlight `Browse` nav item when current page is `/offer/:id`
    if (pathname.startsWith('/offer')) return href.startsWith('/browse')

    // highlight `Browse` nav item when current page is `/address/:address`
    if (pathname.startsWith('/address')) return href.startsWith('/browse')

    // highlight `Browse` nav item when current page is `/address/:address`
    if (pathname.startsWith('/report')) return href.startsWith('/browse')

    // highlight `Manage` nav item when current page is `/sell/:id`
    if (pathname.startsWith('/sell')) return href.startsWith('/manage')

    // highlight `Manage` nav item when current page is `/transfer/:id`
    if (pathname.startsWith('/transfer')) return href.startsWith('/manage')

    const trimHref = href.replace(/\?.*$/, '')

    return pathname.startsWith(trimHref)
  }

  const renderNavItem = (item: NavItem) => {
    const { name, href, icon: Icon, joyrideId } = item

    const isCurrent = getIsCurrent(href)

    const linkClasses = classNames(
      isCurrent
        ? 'bg-gradient-to-r from-brand-400 to-brand-600 text-white dark:from-brand-600 dark:to-brand-500'
        : 'text-gray-300 hover:bg-gray-700 hover:text-white dark:text-gray-400 dark:hover:bg-gray-800/70 dark:hover:text-gray-200',
      isMobile ? 'text-base' : 'text-sm',
      `group flex items-center justify-between px-2 py-2 font-medium rounded-md outline-white dark:outline-gray-300`
    )

    const iconClasses = classNames(
      isCurrent ? 'text-white' : 'text-gray-600 group-hover:text-gray-500',
      'mr-4 flex-shrink-0 h-6 w-6'
    )

    if (isMobile && onClose && pathname.split('?')[0] === href) {
      return (
        <button
          key={name}
          type="button"
          onClick={onClose}
          className={classNames(
            linkClasses,
            'w-full text-left focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-white focus:ring-white dark:ring-offset-black dark:focus:ring-gray-300'
          )}
          aria-current="page"
        >
          <span className="inline-flex items-center">
            <Icon className={iconClasses} aria-hidden="true" />
            {name}
          </span>
          {/* {item.name === 'Auctions' && hasAuctions && <LiveIcon isCurrent />} */}
        </button>
      )
    }

    return (
      <Link
        key={name}
        href={href}
        className={linkClasses}
        aria-current={isCurrent ? 'page' : undefined}
        data-joyride-id={joyrideId}
      >
        <span className="inline-flex items-center">
          <Icon className={iconClasses} aria-hidden="true" />
          {name}
        </span>
        {/* {item.name === 'Auctions' && hasAuctions && <LiveIcon isCurrent={isCurrent} />} */}
      </Link>
    )
  }

  const renderResourceItem = (item: ResourceItem) => {
    const { name, href } = item

    const linkClasses = classNames(
      isMobile ? 'text-base' : 'text-sm',
      'flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-2 py-2 font-medium rounded-md outline-white dark:text-gray-400 dark:hover:bg-gray-800/70 dark:hover:text-gray-200 dark:outline-gray-300'
    )

    return (
      <a key={name} href={href} className={linkClasses} target="_blank" rel="noreferrer">
        <span className="truncate capitalize">{name}</span>
      </a>
    )
  }

  return (
    <>
      <div className={classNames(isMobile ? 'mt-1' : '', 'space-y-1')}>
        {navigation.map((item) => renderNavItem(item))}
      </div>

      <div className="mt-10" data-joyride-id="jr-resources">
        <p className="px-2 text-xs font-semibold text-gray-400 uppercase tracking-wider dark:text-gray-600">
          Resources
        </p>
        <div className="mt-2 space-y-1">{resources.map((item) => renderResourceItem(item))}</div>
      </div>

      <div className="hidden lg:block mt-10" data-joyride-id="jr-community">
        <p className="px-2 text-xs font-semibold text-gray-400 uppercase tracking-wider dark:text-gray-600">
          Community
        </p>
        <div className="mt-2 space-y-1">{community.map((item) => renderResourceItem(item))}</div>
      </div>
    </>
  )
}
