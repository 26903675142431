import axios, { AxiosResponseHeaders } from 'axios'
import { IPFS_GATEWAY_URL } from '@/data/constants'
import { hasResponseStatus } from 'helpers/errors'
import { getIpfsCID } from 'helpers/ipfs'
import { getActiveImageSize } from 'helpers/images'
import { CONFIGURED_HOSTS } from './AssetMedia.constants'
import type { MediaInfo, MediaType } from './AssetMedia.types'
import type { AssetMediaProps } from './AssetMedia'

export const fetchMediaInfo = async (url: string): Promise<MediaInfo> => {
  const getContentType = (contentType: string): string | null => {
    const mediaTypes = ['image', 'video', 'audio']

    return mediaTypes.some((mediaType) => contentType.startsWith(mediaType)) ? contentType : null
  }

  const getContentLength = (headers: AxiosResponseHeaders): number | undefined => {
    return headers['content-length'] ? parseInt(headers['content-length']) : undefined
  }

  if (url.startsWith('/img/')) {
    return { url, contentType: 'image/jpeg' }
  }

  if (url.startsWith('https://')) {
    const httpsHead = await axios.head(url)
    const contentType = getContentType(httpsHead.headers['content-type'])

    if (contentType) {
      const contentLength = getContentLength(httpsHead.headers)
      return { url, contentType, contentLength }
    }

    throw new Error('Invalid or unknown media type')
  }

  if (url.startsWith('ipfs://')) {
    const cid = getIpfsCID(url)

    if (cid) {
      const nfdUrl = `https://images.nf.domains/ipfs/${cid}`
      const algonodeUrl = `${IPFS_GATEWAY_URL}/ipfs/${cid}`

      try {
        const nfdHeadReq = await axios.head(nfdUrl)
        const contentType = getContentType(nfdHeadReq.headers['content-type'])

        if (contentType) {
          const contentLength = getContentLength(nfdHeadReq.headers)
          return { url: nfdUrl, contentType, contentLength }
        }
      } catch (error) {
        if (!hasResponseStatus(error, 404)) {
          console.error('Error fetching from images.nf.domains:', error)
        }
      }

      try {
        const algonodeHeadReq = await axios.head(algonodeUrl)
        const contentType = getContentType(algonodeHeadReq.headers['content-type'])

        if (contentType) {
          const contentLength = getContentLength(algonodeHeadReq.headers)
          return { url: algonodeUrl, contentType, contentLength }
        }

        if (algonodeHeadReq.headers['content-type'] === 'application/json') {
          const json = await axios.get(algonodeUrl)
          const metadata = json.data

          const ipfsMediaUrl = metadata.image || metadata.animation_url
          const mediaCID = getIpfsCID(ipfsMediaUrl)

          if (mediaCID) {
            const mediaUrl = `${IPFS_GATEWAY_URL}/ipfs/${mediaCID}`
            const mediaHeadRq = await axios.head(mediaUrl)
            const contentType = getContentType(mediaHeadRq.headers['content-type'])

            if (contentType) {
              const contentLength = getContentLength(mediaHeadRq.headers)
              return { url: mediaUrl, contentType, contentLength }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching from ipfs.algonode.xyz:', error)
      }

      throw new Error('Failed to fetch IPFS media')
    }
  }

  throw new Error(`Invalid URL: ${url}`)
}

export const isType = (type: MediaType, contentType: string): boolean => {
  return contentType.startsWith(`${type}/`)
}

export const isImage = (contentType: string): boolean => {
  return isType('image', contentType)
}

export const isVideo = (contentType: string): boolean => {
  return isType('video', contentType)
}

export const isAudio = (contentType: string): boolean => {
  return isType('audio', contentType)
}

export const isLocalImage = (url: string): boolean => {
  return url.startsWith('/img/')
}

export const isConfiguredHost = (url: string): boolean => {
  try {
    if (!url.startsWith('https://')) {
      throw new Error('Invalid URL')
    }
    const host = new URL(url).host

    return CONFIGURED_HOSTS.includes(host)
  } catch (error) {
    return false
  }
}

/**
 * This is used to determine the brightness of the icon in the loading and error states.
 * @param props - props passed to an instance of AssetMedia
 * @returns true if the target display size is <= 160px
 */
export const isSmallImage = (props: Partial<AssetMediaProps>): boolean => {
  let size: number | undefined

  if (props.sizes) {
    size = getActiveImageSize(props.sizes)
  } else if (props.width) {
    size = props.width as number
  }

  if (size) {
    return size <= 160
  }

  return false
}
