import axios from 'axios'
import { BlueskyProfileData } from 'pages/api/bluesky/[id]'

export default async function fetchBlueskyDisplayName(id: string | undefined): Promise<string> {
  if (!id) {
    return ''
  }

  try {
    const { data } = (await axios.get('/api/bluesky/' + id)) as { data: BlueskyProfileData }
    return data.handle
  } catch {
    return id
  }
}
