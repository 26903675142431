import { CgMail } from 'react-icons/cg'
import { FaDiscord, FaTelegram } from 'react-icons/fa'
import { FaBluesky, FaXTwitter } from 'react-icons/fa6'
import { formatUsername } from 'helpers/utilities'
import type { SocialLinkDefs } from './SocialLinks.types'

export const linkDefs: SocialLinkDefs = {
  blueskydid: {
    url: (handle: string) => `https://bsky.app/profile/${handle}`,
    icon: FaBluesky
  },
  twitter: {
    url: (username: string) => `https://x.com/${formatUsername(username, true)}`,
    icon: FaXTwitter
  },
  email: {
    url: (email: string) => `mailto:${email}`,
    icon: CgMail
  },
  telegram: {
    url: (username: string) => `https://t.me/${username}`,
    icon: FaTelegram
  },
  discord: {
    url: (username: string) => `https://discord.com/users/${username}`,
    icon: FaDiscord
  }
}
