import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ICustomError } from 'api/customError'
import fetchDiscordName from 'api/utils/fetchDiscordName'
import fetchTelegramName from 'api/utils/fetchTelegramName'
import fetchBlueskyDisplayName from 'api/utils/fetchBlueskyDisplayName'
import { NODE_ENV } from 'src/data/constants'
import type { NfdRecord } from 'api/api-client'

export default function useSocialLinks(nfd: NfdRecord) {
  const isLocalhost = NODE_ENV === 'development'

  const enableTelegramQuery = nfd.properties?.verified?.telegram !== undefined && !isLocalhost

  const telegramQuery = useQuery<string, ICustomError>(
    ['telegram', { nfd: nfd.name, username: nfd.properties?.verified?.telegram }],
    () => fetchTelegramName(nfd.properties?.verified?.telegram),
    {
      enabled: enableTelegramQuery,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  const enableDiscordQuery = nfd.properties?.verified?.discord !== undefined && !isLocalhost

  const discordQuery = useQuery<string, ICustomError>(
    ['discord', { nfd: nfd.name, username: nfd.properties?.verified?.discord }],
    () => fetchDiscordName(nfd.properties?.verified?.discord),
    {
      enabled: enableDiscordQuery,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  const blueskyQuery = useQuery<string, ICustomError>(
    ['bluesky', { nfd: nfd.name, username: nfd.properties?.verified?.blueskydid }],
    () => fetchBlueskyDisplayName(nfd.properties?.verified?.blueskydid),
    {
      enabled: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  const isLoading = useMemo(() => {
    return (
      telegramQuery.isInitialLoading ||
      discordQuery.isInitialLoading ||
      blueskyQuery.isInitialLoading
    )
  }, [discordQuery.isInitialLoading, telegramQuery.isInitialLoading, blueskyQuery.isInitialLoading])

  const hasNoVerifiedSocials = useMemo(() => {
    const { twitter, email } = nfd.properties?.verified || {}

    return [twitter, email, telegramQuery.data, discordQuery.data, blueskyQuery.data].every(
      (value) => value === undefined
    )
  }, [discordQuery.data, blueskyQuery.data, nfd.properties?.verified, telegramQuery.data])

  return {
    telegramQuery,
    discordQuery,
    blueskyQuery,
    isLoading,
    hasNoVerifiedSocials
  }
}
